<template>
    <div class="l-inline l-center-v l-spread l-padded-x navigation-bar">
        <div v-if="displayLogo" class="button-placeholder"></div>

        <icon-button v-else @click="$emit('navigateback')">
            <arrow-left-icon width="24" height="24" />
        </icon-button>

        <div>
            <transition name="fade" mode="out-in">
                <div
                    v-if="displayLogo"
                    :class="{ 'adnexo-logo': !isWhitelabled }"
                    :data-alt="title"
                >
                    <img :src="logo()" :alt="title" height="28" />
                </div>

                <div v-else>{{ title }}</div>
            </transition>
        </div>

        <icon-button v-if="collapsed" @click="$emit('expand')">
            <expand-more-icon width="24" height="24" />
        </icon-button>

        <icon-button v-else @click="$emit('collapse')">
            <expand-less-icon width="24" height="24" />
        </icon-button>
    </div>
</template>

<script>
import ArrowLeftIcon from './icons/ArrowLeftIcon'
import AssetHelpers from '../mixins/AssetHelpers'
import Whitelabling from '../mixins/Whitelabling'
import ExpandLessIcon from './icons/ExpandLessIcon'
import ExpandMoreIcon from './icons/ExpandMoreIcon'
import IconButton from './IconButton'

export default {
    name: 'NavigationBar',
    components: {
        ArrowLeftIcon,
        ExpandLessIcon,
        ExpandMoreIcon,
        IconButton,
    },
    mixins: [AssetHelpers, Whitelabling],
    props: {
        collapsed: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        displayLogo() {
            return (
                this.$route.name === 'search' ||
                this.$route.name === 'globalAnimalActivityChart'
            )
        },
        title() {
            if (this.$route.query.customNavigationTitle) {
                return this.$t(
                    `router.${this.$route.query.customNavigationTitle}`
                )
            }

            if (this.$route.name === 'allAssets') {
                const items = this.$t('shared.assets')
                return this.$t('allItems', { items })
            }

            if (this.$route.name === 'editAsset') {
                const item = this.$t('shared.asset')
                return this.$t('editItem', { item })
            }

            if (this.$route.name === 'locationAnimalActivityChart') {
                return this.$t('router.location')
            }

            if (this.$route.meta.isChart) {
                const { name } = this.$route.matched[
                    this.$route.matched.length - 2
                ]
                return this.$t(`router.${name}`)
            }

            return this.$t(`router.${this.$route.name}`)
        },
    },
}
</script>

<i18n>
{
    "en": {
        "allItems": "All {items}",
        "editItem": "Edit {item}"
    },
    "de": {
        "allItems": "Alle {items}",
        "editItem": "{item} Bearbeiten"
    },
    "it": {
        "allItems": "Tutti {items}",
        "editItem": "Modificare {item}"
    }
}
</i18n>

<style lang="scss" scoped>
.navigation-bar {
    height: 64px;
    user-select: none;

    .button-placeholder {
        width: 34px;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.15s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.adnexo-logo {
    @include respond-to('for-mobile-down') {
        &:before {
            content: attr(data-alt);
        }
        img {
            display: none;
        }
    }
}
</style>

<style lang="scss">
.navigation-bar {
    .icon-button {
        margin: 0 5px;
    }
}
</style>
