<script>
import { mapGetters, mapMutations } from 'vuex'
import { findRealParent } from 'vue2-leaflet'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import {
    featureGroup,
    tooltip,
    polyline,
    icon,
    marker,
    LatLngBounds,
} from 'leaflet'
import geometryutil from 'leaflet-geometryutil'
import { NonOverlappingFeatureGroup } from '../leaflet/NonOverlappingFeatureGroup'
import { DirectionalMarker } from '../leaflet/DirectionalMarker'

momentDurationFormatSetup(moment)

const SHOWING_MARKERS_THRESHOLD = 0
const HIGHLIGHTED_TRACE_COLOR = '#EF2655'
const HIGHTLIGHTED_MARKER_COLOR = '#F89DB2'

export default {
    name: 'TripHistoryLayer',
    props: {
        mapZoom: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            featureGroup: null,
            markers: [],
            leafletParentContainer: null,
            showingMarkers: false,
        }
    },
    computed: {
        ...mapGetters('map', ['getActiveLayer']),
        ...mapGetters('trips', ['getTrips', 'getSelectedTrip']),
        traceColor() {
            return this.getActiveLayer.traceColor || '#A4C7F5'
        },
        traceBorderColor() {
            return this.getActiveLayer.traceBorderColor || '#228CDB'
        },
        tripLayers() {
            let layers = new Map()
            let allLayers = []
            let allPathLayers = []
            let allMarkers = []
            for (let trip of this.getTrips) {
                let tripMarkers = this.createDirectionMarkerForTrip(trip)
                let pathLine = polyline(trip.measurements, {
                    color: this.traceColor,
                    interactive: true,
                    weight: 7,
                })
                pathLine = this.addTooltipToPolyline(pathLine, trip)

                this.addTripEvents(pathLine, trip.id)
                tripMarkers.forEach(m => this.addTripEvents(m, trip.id))

                let tripGroup = featureGroup([pathLine, ...tripMarkers])
                let tripMarkerGroup = new NonOverlappingFeatureGroup(
                    tripMarkers
                )

                layers.set(trip.id, {
                    markers: tripMarkerGroup,
                    pathLine: pathLine,
                    layer: tripGroup,
                })
                allLayers.push(tripGroup)
                allPathLayers.push(pathLine)
                allMarkers = allMarkers.concat(tripMarkers)
            }
            let allPathLinesFeatureGroup = featureGroup(allPathLayers)
            let allMarkersFeatureGroup = new NonOverlappingFeatureGroup(
                allMarkers
            )
            return {
                layers: layers,
                allPaths: allPathLinesFeatureGroup,
                allMarkers: allMarkersFeatureGroup,
            }
        },
        assetHistoryCoordinates() {
            return this.getTrips.reduce(
                (prev, curr) => prev.concat(curr.measurements),
                []
            )
        },
    },
    watch: {
        getTrips() {
            this.refreshLeafletHistoryLayer()
            this.$emit('alignMap', this.assetHistoryCoordinates)
        },
        getSelectedTrip(newValue, oldTrip) {
            if (oldTrip !== null) {
                this.tripLayers.layers.get(oldTrip.id)?.pathLine.setStyle({
                    color: this.traceColor,
                })
            }
            this.refreshLeafletHistoryLayer()
            if (newValue) {
                let positions = newValue.measurements
                this.mapFlyToIfNotVisible(positions)
            }
        },
        mapZoom() {
            this.refreshLeafletHistoryLayer()
        },
        getActiveLayer() {
            // When the map style changes, the trip path may need to change style as well
            this.refreshLeafletHistoryLayer()
        },
    },
    mounted() {
        this.leafletParentContainer = findRealParent(this.$parent, true)
        this.refreshLeafletHistoryLayer()
        this.$emit('alignMap', this.assetHistoryCoordinates)
    },
    beforeDestroy() {
        this.removeLeafletLayer()
    },
    methods: {
        ...mapMutations('trips', ['setSelectedTripID']),
        formatDistance(distance = 0) {
            return distance >= 1000
                ? `${(distance / 1000).toFixed(2)}km`
                : `${distance}m`
        },
        mapFlyToIfNotVisible(positions) {
            let bounds = new LatLngBounds(positions)
            if (
                !this.leafletParentContainer.mapObject
                    .getBounds()
                    .contains(bounds)
            ) {
                this.leafletParentContainer.mapObject.flyToBounds(bounds, {})
            }
        },
        getTimeFormatted(timestamp) {
            return moment(timestamp).format('DD.MM.YYYY HH:mm:ss')
        },
        refreshLeafletHistoryLayer() {
            this.removeLeafletLayer()
            this.addLeafletHistoryLayer()
        },
        addLeafletHistoryLayer() {
            if (this.getTrips?.length) {
                this.featureGroup = featureGroup()

                this.addPathLayer(this.featureGroup)
                this.addMarkerLayer(this.featureGroup)

                this.leafletParentContainer.mapObject.addLayer(
                    this.featureGroup
                )
            }
        },

        addPathLayer(historyLayerGroup) {
            if (this.getSelectedTrip === null) {
                historyLayerGroup.addLayer(this.tripLayers.allPaths)
            } else {
                historyLayerGroup.addLayer(
                    this.tripLayers.layers
                        .get(this.getSelectedTrip.id)
                        .pathLine.setStyle({ color: HIGHLIGHTED_TRACE_COLOR })
                )
            }
        },
        addMarkerLayer(historyLayerGroup) {
            if (this.mapZoom >= SHOWING_MARKERS_THRESHOLD) {
                if (this.getSelectedTrip === null) {
                    historyLayerGroup.addLayer(this.tripLayers.allMarkers)
                } else {
                    historyLayerGroup.addLayer(
                        this.tripLayers.layers.get(this.getSelectedTrip.id)
                            .markers
                    )
                }
            }
        },
        removeLeafletLayer() {
            if (this.featureGroup) {
                this.leafletParentContainer.mapObject.removeLayer(
                    this.featureGroup
                )
            }
        },
        addTooltipToPolyline(pathLine, trip) {
            if (trip.length > 1) {
                const firstPosition = trip[0]
                const lastPosition = trip[trip.length - 1]
                const timeDiff = moment(lastPosition.timestamp).diff(
                    moment(firstPosition.timestamp)
                )
                const tripDuration = moment
                    .duration(timeDiff)
                    .format('h[h] mm[m] ss[s]')
                const tripStart = this.getTimeFormatted(firstPosition.timestamp)
                const tripEnd = this.getTimeFormatted(lastPosition.timestamp)

                const tooltipContent = [
                    `${this.$t('duration')}: ${tripDuration}`,
                    `${this.$t('start')}: ${tripStart}`,
                    `${this.$t('end')}: ${tripEnd}`,
                ].join('<br>')

                const tooltippObj = tooltip()
                tooltippObj.setContent(tooltipContent)
                if (pathLine?.bindTooltip) {
                    pathLine.bindTooltip(tooltippObj, { sticky: true })
                }
            }
            return pathLine
        },
        createDirectionMarkerForTrip(trip) {
            const directionMarkers = []
            let tripDuration = null
            let tripStart = null
            let tripEnd = null

            if (trip.last_measurement) {
                const firstPosition = trip.first_measurement
                const lastPosition = trip.last_measurement
                const timeDiff = moment(lastPosition.timestamp).diff(
                    moment(firstPosition.timestamp)
                )
                tripDuration = moment.duration(timeDiff)
                tripStart = this.getTimeFormatted(firstPosition.timestamp)
                tripEnd = this.getTimeFormatted(lastPosition.timestamp)
            }

            trip.measurements?.forEach((position, i) => {
                let marker = null
                let positionNext =
                    i + 1 < trip.measurements.length
                        ? trip.measurements[i + 1]
                        : null

                if (trip.first_measurement.id === position.id) {
                    marker = this.makeTripStartMarker(position)
                } else if (trip.last_measurement.id === position.id) {
                    marker = this.makeTripEndMarker(position)
                } else {
                    marker = this.makeInTripMarker(position, positionNext)
                }

                let tooltipContent = this.getTimeFormatted(position.timestamp)
                if (tripDuration !== null) {
                    tooltipContent += `<hr>${this.$t(
                        'duration'
                    )}: ${tripDuration.format('h[h] mm[m] ss[s]')}`
                    tooltipContent += `<br>${this.$t('start')}: ${tripStart}`
                    tooltipContent += `<br>${this.$t('end')}: ${tripEnd}`
                    const distance = trip.last_measurement.trip_distance
                    if (typeof distance === 'number') {
                        tooltipContent += `<br>${this.$t(
                            'distance'
                        )}: ${this.formatDistance(distance)}`
                    }
                }

                const tooltippObj = tooltip()
                tooltippObj.setContent(tooltipContent)
                if (marker?.bindTooltip) {
                    marker.bindTooltip(tooltippObj)
                }
                if (marker !== null) {
                    directionMarkers.push(marker)
                }
            })

            return directionMarkers
        },
        makeInTripMarker(position, positionNext) {
            let bearing = 0
            if (positionNext !== null) {
                bearing = geometryutil.bearing(position, positionNext)
            }

            let color = this.traceBorderColor
            if (this.selectedHistoryLocationID === position.id) {
                color = HIGHTLIGHTED_MARKER_COLOR
            }

            return new DirectionalMarker(position, {
                bearing,
                scale: 1.6,
                color,
                weight: 0,
                opacity: 1,
                fillOpacity: 1,

                axItem: position,
            })
        },
        makeTripStartMarker(assetHistoryEntry) {
            const iconUrl = require('@/assets/icons/pin.svg')
            const iconWidthAndHeight = 30
            const iconElevation = 0
            const className = ''
            const startTripIcon = icon({
                iconUrl,
                iconSize: [iconWidthAndHeight, iconWidthAndHeight],
                iconAnchor: [
                    iconWidthAndHeight / 2,
                    iconWidthAndHeight + iconElevation,
                ],
                tooltipAnchor: [
                    iconWidthAndHeight / 2,
                    -iconElevation - iconWidthAndHeight / 2,
                ],
                className,
            })
            return marker(assetHistoryEntry, {
                icon: startTripIcon,
                axItem: assetHistoryEntry,
            })
        },
        makeTripEndMarker(assetHistoryEntry) {
            const iconUrl = require('@/assets/icons/flag.svg')
            const iconWidthAndHeight = 30
            const className = ''
            const startTripIcon = icon({
                iconUrl,
                iconSize: [iconWidthAndHeight, iconWidthAndHeight],
                iconAnchor: [5, iconWidthAndHeight],
                tooltipAnchor: [
                    iconWidthAndHeight / 2,
                    -iconWidthAndHeight / 2,
                ],
                className,
            })
            return marker(assetHistoryEntry, {
                icon: startTripIcon,
                axItem: assetHistoryEntry,
            })
        },
        addTripEvents(layerObject, tripIndex) {
            layerObject.on('click', () => {
                if (this.getSelectedTrip === null) {
                    this.setSelectedTripID(tripIndex)
                    this.$nextTick(() => {
                        this.leafletParentContainer.mapObject.once(
                            'click',
                            () => {
                                this.setSelectedTripID(null)
                            }
                        )
                    })
                } else {
                    this.setSelectedTripID(null)
                }
            })
            layerObject.on('mouseover', () => {
                if (this.getSelectedTrip === null) {
                    this.tripLayers.layers.get(tripIndex).pathLine.setStyle({
                        color: HIGHLIGHTED_TRACE_COLOR,
                    })
                }
            })
            layerObject.on('mouseout', () => {
                if (this.getSelectedTrip === null) {
                    this.tripLayers.layers.get(tripIndex).pathLine.setStyle({
                        color: this.traceColor,
                    })
                }
            })
        },
    },
    render: function(h) {
        return h() // avoid warning message because we don't have a template
    },
}
</script>

<i18n>
{
    "en": {
        "start": "Start",
        "end": "End",
        "duration": "Duration",
        "distance": "Distance"
    },
    "de": {
        "start": "Start",
        "end": "Ende",
        "duration": "Dauer",
        "distance": "Distanz"
    },
    "it": {
        "start": "Start",
        "end": "End",
        "duration": "Duration",
        "distance": "Distanza"
    }
}
</i18n>
