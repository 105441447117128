<template>
    <asset-marker
        :asset="asset"
        :is-selected="isSelected"
        :interactive="interactive"
        :information-pills="informationPills"
        :visible="visible"
        @markerClicked="$emit('markerClicked', $event)"
    />
</template>

<script>
import AssetMarker from './AssetMarker'
import { formatHelper } from '@/utils'

export default {
    name: 'AirQualityMarker',
    components: {
        AssetMarker,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        humidity() {
            return this.asset.asset_details?.sensor_data?.humidity?.value
        },
        temperature() {
            return this.asset.asset_details?.sensor_data?.temperature?.value
        },
        co2() {
            return this.asset.asset_details?.sensor_data?.co2?.value
        },
        voc() {
            return this.asset.asset_details?.sensor_data?.voc?.value
        },
        informationPills() {
            const pills = []

            if (this.humidity) {
                pills.push({
                    text: Math.round(this.humidity),
                    className: 'sensor-data sensor-data-percentage',
                })
            }

            if (this.temperature) {
                pills.push({
                    text: Math.round(this.temperature),
                    className: 'sensor-data sensor-data-temperature',
                })
            }

            if (this.co2) {
                pills.push({
                    text: formatHelper.kFormatter(Math.round(this.co2)),
                    className: 'sensor-data sensor-data-co2',
                })
            }

            if (this.voc) {
                pills.push({
                    text: formatHelper.kFormatter(this.voc),
                    className: 'sensor-data sensor-data-voc',
                })
            }

            return pills
        },
    },
}
</script>
