var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.mode]},[_c('l-map',{ref:"map",attrs:{"options":{
            attributionControl: false,
            editable: true,
            preferCanvas: true,
            zoomControl: false,
        },"max-bounds":[
            [-90, -270],
            [90, 270] ],"max-bounds-viscosity":0.75,"max-zoom":_vm.activeLayer.maxZoom || _vm.baseTileOptions.maxZoom,"min-zoom":_vm.activeLayer.minZoom || _vm.baseTileOptions.minZoom},on:{"update:zoom":_vm.zoomUpdated,"locationfound":_vm.onLocationFound,"locationerror":_vm.onLocationError,"mouseup":function($event){return _vm.setShouldFollowActiveTrackerUpdates(false)}}},[_c('location-layer',{attrs:{"locations":_vm.locationsToDisplay},on:{"alignMap":_vm.alignMap}}),(_vm.mode === 'history')?_c('history-layer',{on:{"alignMap":_vm.alignMap}}):_vm._e(),(_vm.mode === 'triphistory')?_c('trip-history-layer',{attrs:{"map-zoom":_vm.zoom},on:{"alignMap":_vm.alignMap}}):_vm._e(),(_vm.mode === 'connection-heatmap')?_c('connection-heatmap-layer',{attrs:{"measurements":_vm.getHeatmapData || []},on:{"alignMap":_vm.alignMap}}):_vm._e(),(_vm.mode === 'editing')?_c('create-location-layer'):_vm._e(),(_vm.showTrackerLayer)?_c('tracker-layer',{attrs:{"trackers":_vm.trackersToDisplay,"excluded-from-clustering":[_vm.activeTrackerOnMap],"only-ids":_vm.filteredTrackerIds},on:{"alignMap":_vm.alignMap}}):_vm._e(),_c('tracker-trace-layer',{attrs:{"coordinates":_vm.activeTrackerTrace}}),_c('user-layer'),_c('l-control-attribution',{attrs:{"position":"bottomright","prefix":_vm.appVersion}}),_c('l-control-scale',{attrs:{"position":"bottomleft","imperial":false}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }