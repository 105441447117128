import L from 'leaflet'

L.Canvas.include({
    _updateDirectionalMarker: function(layer) {
        if (!this._drawing || layer._empty()) {
            return
        }

        let p = layer._point
        let ctx = this._ctx

        this._layers[layer._leaflet_id] = layer

        // let storeTransform = ctx.getTransform()

        let bearing = layer.options.bearing || 0
        let scale = layer.options.scale || 1

        let iconSize = 24
        let pointer = new Path2D(
            'M11.65,20a.64.64,0,0,0,.53.47.53.53,0,0,0,.58-.32L18.39,6.84a.59.59,0,0,0-.14-.66A.61.61,0,0,0,17.6,6L4.33,11.68a.53.53,0,0,0-.32.58.62.62,0,0,0,.46.52l5.74,1.45Z'
        )

        ctx.save()
        ctx.beginPath()

        ctx.translate(p.x - iconSize / 2, p.y - iconSize / 2)

        ctx.translate(iconSize / 2, iconSize / 2)
        bearing = bearing - 45 // Because original shape is pointing at a 45 degree angle
        ctx.rotate((bearing * Math.PI) / 180)
        ctx.scale(scale, scale)
        ctx.translate(-(iconSize / 2), -(iconSize / 2))

        pointer.closePath()
        this._fillStroke(ctx, layer)
        ctx.fill(pointer)
        ctx.closePath()

        ctx.restore()
    },
})

export var DirectionalMarker = L.CircleMarker.extend({
    options: {
        bearing: 0,
        scale: 1,
    },
    _size: 24,
    initialize: function(latlng, options) {
        L.Util.setOptions(this, options)
        this._latlng = L.latLng(latlng)
        this.options.radius = (this._size * this.options.scale) / 2
        this._radius = this.options.radius
    },
    _updatePath: function() {
        this._renderer._updateDirectionalMarker(this)
    },
})
