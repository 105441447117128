<template>
    <asset-marker
        :asset="asset"
        :is-selected="isSelected"
        :interactive="interactive"
        :visible="visible"
        :information-pills="informationPills"
        @markerClicked="$emit('markerClicked', $event)"
    />
</template>

<script>
import AssetMarker from './../../AssetMarker'
import { measurementHelper } from '@/utils'
import { sbbFillLevelThresholds } from './sbbBinSettings'

export default {
    name: 'SbbBinMarker',
    components: {
        AssetMarker,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        fillLevel() {
            if (
                this.asset?.asset_details?.sensor_data?.distance?.value !==
                undefined
            ) {
                return measurementHelper.convertToSbbBinLevel(
                    this.asset.asset_details.sensor_data.distance.value
                )
            }
            return undefined
        },
        isPaperBin() {
            return (
                this.asset?.asset_details?.asset_type_identifier ===
                'sbb-bin-paper'
            )
        },
        fullnessClass() {
            if (this.isPaperBin) {
                return this.fillLevel <= sbbFillLevelThresholds.paper.low
                    ? ' sensor-data-bin-empty'
                    : this.fillLevel >= sbbFillLevelThresholds.paper.high
                    ? ' sensor-data-bin-full'
                    : ' sensor-data-bin-medium'
            }
            return this.fillLevel <= sbbFillLevelThresholds.normal.low
                ? ' sensor-data-bin-empty'
                : this.fillLevel >= sbbFillLevelThresholds.normal.high
                ? ' sensor-data-bin-full'
                : ' sensor-data-bin-medium'
        },
        informationPills() {
            let pills = []

            if (this.fillLevel !== undefined) {
                let classes = 'sensor-data sensor-data-percentage'
                classes += this.fullnessClass
                pills.push({
                    text: this.fillLevel,
                    className: classes,
                })
            }
            if (this.asset?.asset_details?.sensor_data?.distance?.value) {
                let classes = 'sensor-data sensor-data-distance'
                pills.push({
                    text: this.asset.asset_details.sensor_data.distance.value,
                    className: classes,
                })
            }
            return pills
        },
    },
}
</script>
