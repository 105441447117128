<template>
    <div :class="[mode]">
        <l-map
            ref="map"
            :options="{
                attributionControl: false,
                editable: true,
                preferCanvas: true,
                zoomControl: false,
            }"
            :max-bounds="[
                [-90, -270],
                [90, 270],
            ]"
            :max-bounds-viscosity="0.75"
            :max-zoom="activeLayer.maxZoom || baseTileOptions.maxZoom"
            :min-zoom="activeLayer.minZoom || baseTileOptions.minZoom"
            @update:zoom="zoomUpdated"
            @locationfound="onLocationFound"
            @locationerror="onLocationError"
            @mouseup="setShouldFollowActiveTrackerUpdates(false)"
        >
            <location-layer
                :locations="locationsToDisplay"
                @alignMap="alignMap"
            />

            <history-layer v-if="mode === 'history'" @alignMap="alignMap" />
            <trip-history-layer
                v-if="mode === 'triphistory'"
                :map-zoom="zoom"
                @alignMap="alignMap"
            />

            <connection-heatmap-layer
                v-if="mode === 'connection-heatmap'"
                :measurements="getHeatmapData || []"
                @alignMap="alignMap"
            />

            <create-location-layer v-if="mode === 'editing'" />

            <tracker-layer
                v-if="showTrackerLayer"
                :trackers="trackersToDisplay"
                :excluded-from-clustering="[activeTrackerOnMap]"
                :only-ids="filteredTrackerIds"
                @alignMap="alignMap"
            />

            <tracker-trace-layer :coordinates="activeTrackerTrace" />

            <user-layer />

            <l-control-attribution
                position="bottomright"
                :prefix="appVersion"
            />

            <l-control-scale
                position="bottomleft"
                :imperial="false"
            ></l-control-scale>
        </l-map>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { tileLayer, mapboxGL } from 'leaflet'
import { LControlAttribution, LControlScale, LMap } from 'vue2-leaflet'
import debounce from 'lodash.debounce'
import 'mapbox-gl-leaflet'

import ConnectionHeatmapLayer from './ConnectionHeatmapLayer'
import CreateLocationLayer from './CreateLocationLayer'
import HistoryLayer from './HistoryLayer'
import TripHistoryLayer from './TripHistoryLayer'
import LocationLayer from './LocationLayer'
import TrackerLayer from './TrackerLayer'
import TrackerTraceLayer from './TrackerTraceLayer'
import UserLayer from './UserLayer'

export default {
    name: 'AxMap',
    components: {
        ConnectionHeatmapLayer,
        CreateLocationLayer,
        HistoryLayer,
        LControlAttribution,
        LControlScale,
        LMap,
        LocationLayer,
        TrackerLayer,
        TrackerTraceLayer,
        UserLayer,
        TripHistoryLayer,
    },
    data() {
        return {
            appVersion: process.env.VUE_APP_VERSION,
            baseTileOptions: {
                maxZoom: 20,
                minZoom: 2,
            },
            tileProviders: [
                {
                    id: 'standard',
                    traceColor: '#13365E',
                    traceBorderColor: null,
                    layer: tileLayer(
                        'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?' +
                            'access_token=' +
                            process.env.VUE_APP_MAPBOX_TOKEN,
                        { maxZoom: 20, tileSize: 512, zoomOffset: -1 }
                    ),
                },
                {
                    id: 'outdoor',
                    traceColor: 'black',
                    traceBorderColor: null,
                    layer: tileLayer(
                        'https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?' +
                            'access_token=' +
                            process.env.VUE_APP_MAPBOX_TOKEN,
                        { maxZoom: 20, tileSize: 512, zoomOffset: -1 }
                    ),
                },
                {
                    id: 'street',
                    traceColor: 'black',
                    traceBorderColor: null,
                    layer: tileLayer(
                        'https://api.mapbox.com/styles/v1/mapbox/navigation-preview-day-v4/tiles/{z}/{x}/{y}?' +
                            'access_token=' +
                            process.env.VUE_APP_MAPBOX_TOKEN,
                        { maxZoom: 20, tileSize: 512, zoomOffset: -1 }
                    ),
                },
                {
                    id: 'satellite',
                    traceColor: '#03fc07',
                    traceBorderColor: 'black',
                    layer: tileLayer(
                        'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?' +
                            'access_token=' +
                            process.env.VUE_APP_MAPBOX_TOKEN,
                        { maxZoom: 20, tileSize: 512, zoomOffset: -1 }
                    ),
                },
                {
                    id: 'swisstopo',
                    traceColor: '#03fc07',
                    traceBorderColor: 'black',
                    layer: tileLayer(
                        'https://wmts20.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/' +
                            '{z}/{x}/{y}.jpeg',
                        { maxZoom: 20, tileSize: 256 }
                    ),
                },
                {
                    id: 'vectormap',
                    traceColor: '#A4C7F5',
                    traceBorderColor: '#516677',
                    layer: mapboxGL({
                        accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
                        style: 'mapbox://styles/mapbox/light-v10?optimize=true',
                    }),
                    maxZoom: 23,
                },
            ],
            zoom: null,
        }
    },
    computed: {
        ...mapState('locations', ['locations']),
        ...mapState('map', ['activeLayer', 'savedBounds']),
        ...mapState('tracker', [
            'activeTrackerOnMap',
            'activeTrackerTrace',
            'filteredTrackerIds',
            'shouldFollowActiveTrackerUpdates',
            'trackers',
        ]),
        ...mapGetters('locations', ['getActiveLocationOnMap']),
        ...mapGetters('map', ['getHeatmapData']),
        trackersToDisplay() {
            let trackers = this.trackers.filter(
                tracker =>
                    tracker.asset_details.position.latitude &&
                    tracker.asset_details.position.longitude
            )

            return trackers
        },
        locationsToDisplay() {
            // do not show active location if editing a location
            if (this.mode == 'editing' && this.getActiveLocationOnMap) {
                return this.locations.filter(
                    location => location.id != this.getActiveLocationOnMap.id
                )
            }

            return this.locations
        },
        mode() {
            if (
                this.$route.path === '/map/location/create' ||
                this.$route.path.match(/^\/map\/location\/\d*\/edit$/)
            ) {
                return 'editing'
            } else if (
                this.$route.path.match(/^\/map\/assets\/\d*\/location-history$/)
            ) {
                return 'history'
            } else if (
                this.$route.path.match(/^\/map\/assets\/\d*\/trip-history$/)
            ) {
                return 'triphistory'
            } else if (
                this.$route.name === 'connectionHeatmap' ||
                this.$route.name === 'assetConnectionHeatmap' ||
                this.$route.name === 'locationConnectionHeatmap'
            ) {
                return 'connection-heatmap'
            }

            return 'default'
        },
        showTrackerLayer() {
            return !['history', 'connection-heatmap', 'triphistory'].includes(
                this.mode
            )
        },
    },
    watch: {
        activeTrackerTrace(trace) {
            if (this.shouldFollowActiveTrackerUpdates && trace.length > 1) {
                this.$refs.map.mapObject.panTo(trace[0], {
                    animate: true,
                    duration: 1,
                })
            }
        },
    },
    mounted() {
        this.setMapInstance(this.$refs.map.mapObject)
        this.setTileProviders(this.tileProviders)

        if (this.activeLayer.layer) {
            this.$refs.map.mapObject.addLayer(this.activeLayer.layer)
        } else {
            let defaultTileProvider = this.tileProviders[0]

            if (process.env.VUE_APP_DEFAULT_MAP) {
                defaultTileProvider =
                    this.tileProviders.find(
                        map => map.id == process.env.VUE_APP_DEFAULT_MAP
                    ) || defaultTileProvider
            }

            this.$refs.map.mapObject.addLayer(defaultTileProvider.layer)
            this.setActiveLayer(defaultTileProvider)
        }

        if (this.savedBounds) {
            this.$refs.map.mapObject.fitBounds(this.savedBounds)
            this.clearSavedBounds()
        } else {
            const bounds = this.trackers
                .filter(item => item.asset_details)
                .map(item => [item.asset_details.lat, item.asset_details.lng])

            this.$refs.map.mapObject.fitBounds(bounds)
        }
    },
    beforeDestroy() {
        this.$refs.map.mapObject.removeLayer(this.activeLayer.layer)
        this.disablePositionTracking()
    },
    methods: {
        ...mapMutations('map', [
            'clearSavedBounds',
            'setActiveLayer',
            'setMapInstance',
            'setPositionTrackingEnabled',
            'setTileProviders',
            'setUserPosition',
        ]),
        ...mapMutations('tracker', ['setShouldFollowActiveTrackerUpdates']),
        areCoordinatesVisible(coordinates) {
            return coordinates.every(item =>
                this.$refs.map.mapObject.getBounds().contains(item)
            )
        },
        alignMap(bounds) {
            if (
                bounds?.length &&
                (this.$refs.map.mapObject.getZoom() < 12 ||
                    !this.areCoordinatesVisible(bounds))
            ) {
                this.$nextTick(
                    debounce(() => this.$refs.map.fitBounds(bounds), 100)
                )
            }
        },
        disablePositionTracking() {
            this.$refs.map.mapObject.stopLocate()
            this.setPositionTrackingEnabled(false)
        },
        onLocationFound({ latitude, longitude }) {
            this.setUserPosition({
                lat: latitude,
                lng: longitude,
            })
        },
        onLocationError() {
            this.disablePositionTracking()
        },
        zoomUpdated(zoom) {
            this.zoom = zoom
        },
    },
}
</script>

<style lang="scss">
@import '~leaflet/dist/leaflet.css';

.editing {
    path.leaflet-interactive {
        cursor: grab;
    }

    .leaflet-marker-draggable {
        width: 14px !important;
        height: 14px !important;
        margin-left: -7px !important;
        margin-top: -7px !important;
        border-radius: 2px;
        cursor: crosshair;
    }
}

.leaflet-popup-content-wrapper {
    border-radius: 4px;
}
</style>
